.hero {
  width: 100%;
  height: 100dvh;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  perspective: 700px;

}

.hero__container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 5rem;
  padding: 4rem;
}

.hero__sub {
  position: absolute;
  text-align: center;
  top: 12%;
}


.hero__title {
  font-family: 'Exo';
  font-size: 15rem;
  transform: skew(-15deg);
  letter-spacing: 0.03em;
}

.hero__lines {
  position: absolute;
  width: 100%;
  height: 10rem;
  background: linear-gradient(rgba(89, 193, 254, 0.2) 20%, #59c1fe 40%, #59c1fe 60%, rgba(89, 193, 254, 0.2) 80%);
  background-size: 1px 0.5em;
  box-shadow: 0 0 1em rgba(89, 193, 254, 0.4);
  top: 16%;
}

.hero__title::after {
  content: '';
  position: absolute;
  top: -0.1em;
  right: 0.05em;
  width: 0.4em;
  height: 0.4em;

  background: radial-gradient(white 3%, 15%, rgba(255, 255, 255, 0.05) 60%, transparent 80%),
    radial-gradient(rgba(255, 255, 255, 0.2) 50%, transparent 60%) 50% 50% / 5% 100%,
    radial-gradient(rgba(255, 255, 255, 0.2) 50%, transparent 60%) 50% 50% / 70% 5%;
  background-repeat: no-repeat;
}

.hero__title span:first-child {
  display: block;
  text-shadow: 0 0 0.1em #8ba2d0, 0 0 0.2em black, 0 0 5em #165ff3;
  -webkit-text-stroke: 0.06em rgba(0, 0, 0, 0.5);
}

.hero__title span:last-child {
  position: absolute;
  left: 0;
  top: 0;
  background-image: linear-gradient(#032d50 25%, #00a1ef 35%, white 50%, #20125f 50%, #8313e7 55%, #ff61af 75%);
  -webkit-text-stroke: 0.01em #94a0b9;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero__h2 {
  font-family: 'Mr Dafoe';
  margin: 0;
  font-size: 5.5em;
  margin-top: -0.6em;
  color: white;
  text-shadow: 0 0 0.05em #fff, 0 0 0.2em #fe05e1, 0 0 0.3em #fe05e1;
  transform: rotate(-7deg);
}

@media screen and (max-width: 750px) {
  .hero__title {
    font-size: 8rem;
  }

  .hero__lines {
    height: 4rem;
    top: 32%;
  }

  .hero__sub {

    top: 30%;
  }
}

.hero__buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 4rem;
  gap: 2rem; 
}

.hero__button-left,
.hero__button-right {
  flex: none;
}

.hero__button-left {
  margin-right: 1rem; 
}

.hero__button-right {
  margin-left: 1rem; 
}

@media screen and (max-width: 750px) {
  .hero__buttons {
    flex-direction: column;
    gap: 1rem; /* Adjust this value to control the vertical distance between the buttons */
    padding: 0; /* Remove padding when stacked vertically */
    bottom: 2rem; /* Adjust bottom spacing if necessary */
  }

  .hero__button-left,
  .hero__button-right {
    margin: 0; /* Reset margins for mobile */
  }
}