.swiper {
    width: 100%;
    padding: 4rem 1rem;
    margin: 0 auto;
}

.swiper_container {
    height: 100%;
    padding: 2rem 0;
    position: relative;
}

.swiper-slide {
    max-width: 60rem !important;
    max-height: 80em !important;
    position: relative;
}

@media (max-width: 500px) {
    .swiper_container {
        width: 100%;
        height: 100%;
    }

    .swiper-slide {
        max-width: 65rem !important;
        max-height: 100em !important;
    }

    .swiper-slide_img {
        width: 65rem !important;
        height: 80rem !important;
    }
}

.swiper-slide_img {
    width: 55rem;
    height: 55rem;
    border-radius: 2rem;
    object-fit: cover;
}

.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
    display: none;
}

.slider-controler {
    position: relative;
    bottom: 1rem;

    display: flex;
    align-items: center;
    justify-content: center;
}

.slider-controler .swiper-button-next {
    left: 58% !important;
    transform: translateX(-58%) !important;
}

@media (max-width: 990px) {
    .slider-controler .swiper-button-next {
        left: 70% !important;
        transform: translateX(-70%) !important;
    }
}

@media (max-width: 450px) {
    .slider-controler .swiper-button-next {
        left: 80% !important;
        transform: translateX(-80%) !important;
    }
}

@media (max-width: 990px) {
    .slider-controler .swiper-button-prev {
        left: 30% !important;
        transform: translateX(-30%) !important;
    }
    .swiper-slide_img {
        object-fit: cover;
    }
    
}

@media (max-width: 450px) {
    .slider-controler .swiper-button-prev {
        left: 20% !important;
        transform: translateX(-20%) !important;
    }
}

.slider-controler .slider-arrow {
    background: var(--white);
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    left: 42%;
    transform: translateX(-42%);
    filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.slider-controler .slider-arrow ion-icon {
    font-size: 2rem;
    color: var(--primary-color);
    z-index: 10;
}

.slider-controler .slider-arrow::after {
    content: '';
}

.swiper-pagination {
    position: relative;
    width: 100% !important;
    bottom: 1rem;
}

.swiper-pagination .swiper-pagination-bullet {
    background: black;
    filter: drop-shadow(0px 8px 24px #ffffff);
}

.swiper-pagination .swiper-pagination-bullet-active {
    background: var(--primary-color);
}