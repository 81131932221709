@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Send+Flowers&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Black+Ops+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Monoton&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mr+Dafoe&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Exo:wght@900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pixelify+Sans:wght@400..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pixelify+Sans:wght@400..700&family=VT323&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --background-color: #000000;
  --primary-color: rgb(255, 255, 255);
  --accent-color: #D80032;
}

html {
  margin: 0;
  padding: 0;
  font-size: calc(100vw/1920 * 10);
  scroll-behavior: smooth;
}

body {
  font-family: "Pixelify Sans", sans-serif, "VT323", monospace;
  ;
  font-optical-sizing: auto;
}

::-webkit-scrollbar {
  width: 0.5rem;
  background-color: #000000;
}

::-webkit-scrollbar-thumb {
  background-color: #ffffff;
  border-radius: 1rem;
}

@media screen and (max-width: 768px) {

  html,
  body {
    font-size: calc(100vw / 768 * 7);
  }
}

@media screen and (max-width: 500px) {

  html,
  body {
    font-size: calc(100vw / 750 * 10);
  }
}