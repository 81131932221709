.timer__container {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: relative;
  }
  
  /* Background image */
  .timer__bg {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }
  
  /* Logo image */
  .timer__logo {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 150px;
    height: 150px;
  }
  
  /* Timer */
  .timer__text {
    position: absolute;
    top: 45%; /* Moved down from 40% to 45% */
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: 'Press Start 2P';
    font-size: 10rem;
    color: white;
    z-index: 3;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
    white-space: nowrap;
  }
  
  /* Dynamic Phrase */
  .phrase {
    position: absolute;
    top: 65%; /* Moved down from 60% to 65% */
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: 'Press Start 2P';
    font-size: 4rem;
    color: white;
    z-index: 3;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7);
    white-space: nowrap;
    text-align: center;
  }

  @media screen and (max-width: 750px) { 

  }