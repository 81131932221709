.about {
    width: 100%;
    height: 100%;
    padding: 20rem 10rem;
    position: relative;
    overflow: hidden;
}

.about__container {
    width: 100%;
    height: 100%;
}

.about__card {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
}

.about__card__img__container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 50%;
}

.about__card__bold {
    font-weight: 700;
    font-size: 1.2em;
}

.about__card__img {
    width: 45rem;
    justify-self: flex-start;
    object-fit: contain;
    height: 8.625rem;
    position: relative;
    left: -3rem
}

.about__card__sub {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.about__card__title {
    font-size: 5rem;
    font-weight: 700;
}

.about__card__para {
    font-family: "VT323", monospace;
    font-size: 3rem;
    font-weight: 400;
}


@media screen and (max-width: 750px) {
    .about {
        padding: 5rem 2rem;
        height: 100%;
    }

    .about__card__para {
        font-size: 2.5rem;
    }

    .about__card {
        flex-direction: column-reverse;

    }

    .about__card__img {}

    .about__card__img__container {
        width: 100%;
    }
}