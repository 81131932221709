/* Logo.css */
.logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    /* Adjust based on your layout */
}

.logo-image {
    width: 80%;
    /* Adjust the size as needed */
    animation: hover-up-down 3s ease-in-out infinite;
}

@keyframes hover-up-down {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-20px);
        /* Adjust the height of the hover effect */
    }

    100% {
        transform: translateY(0);
    }
}