.contact {
    width: 100%;
    height: 100%;
    padding: 2rem 10rem;
}

.contact__container {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    height: 100%;
    flex: 1;
}

.contact__title {
    font-size: 8rem;
    text-transform: uppercase;
    max-width: 80rem;
    font-family: 'Monoton';
    word-spacing: 0.5rem;
}

.contact__contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    gap: 10rem;
}


@media screen and (max-width: 750px) {
    .contact {
        padding: 15rem 4rem;
    }

    .contact__container {
        justify-content: flex-start;
        width: 100%;
        flex-direction: column;
        gap: 5rem;
    }

    .contact__title {
        width: 100%;
    }

    .contact__contact {
        gap: 4rem;
    }

}