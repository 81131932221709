.timeline_container {
    margin: 6rem 0 2rem 0;
}

.timeline__title {
    background: #8A2387;
    background: -webkit-linear-gradient(to bottom, #F27121, #E94057, #8A2387);
    background: linear-gradient(to bottom, #F27121, #E94057, #8A2387);
    font-size: 3rem;
    font-weight: 600;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
}

.vertical-timeline-element-content {
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);


}

.vertical-timeline--two-columns .vertical-timeline-element-content .vertical-timeline-element-date {
    font-size: 3.5rem;
    font-family: sans-serif;
}


.vertical-timeline-element-icon {
    position: absolute;
    top: 0;
    left: 0;
    background: #EEF7FF;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    box-shadow: none;
}



.timeline__para {
    margin-top: 1rem;
    padding-bottom: 1rem;
    font-size: 2rem;
    color: var(--background-color);
    color: #fff;
    font-weight: 400;
    font-family: 'VT323', monospace;
}

.timeline__line::before {
    background: -webkit-linear-gradient(to bottom, #F27121, #E94057, #8A2387);
}

.timeline__line {
    height: 100%;

}

.timeline__inner_text {
    height: 100%;
    font-family: 'VT323', monospace;

}

.timeline__logo__image {
    width: 80%;
    height: 80%;
    padding-left: 10px;
    padding-top: 10px;
}

@media screen and (max-width: 768px) {
    .timeline__para {
        font-size: 3rem;
    }

    .vertical-timeline-element-content {
        .vertical-timeline-element-date {
            font-size: 4rem;
        }
    }
}