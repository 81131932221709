.logos__slides {
    position: relative;
    display: flex;
    width: 100%;
    overflow: hidden;
    margin: 2rem 0rem;
    background-color: transparent;
}

.logos__slide {
    background-color: transparent;
    white-space: nowrap;
    animation: animate 20s linear infinite;
}

@keyframes animate {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(-100%);
    }
}

.logos__text {
    color: var(--primary-color);
    padding: 1rem 2rem;
    font-size: 4rem;
    margin: 2rem;
}

.Marque__title {
    font-size: 6rem;
    text-align: center;
    text-transform: uppercase;
    margin: 4rem 0;
    font-weight: 700;
}