.team__card {
    user-select: none;
    width: 350px;
    margin: 5rem auto;
    border: 1px solid #ffffff22;
    background-color: #282c34;
    background: linear-gradient(0deg, rgba(40, 44, 52, 1) 0%, rgba(17, 0, 32, .5) 100%);

    box-shadow: 0 7px 20px 5px #00000088;
    border-radius: .7rem;
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
    overflow: hidden;
    transition: .5s all;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4rem 2rem;
    gap: 4rem;
}

.team__card::before {
    position: fixed;
    content: "";
    box-shadow: 0 0 100px 40px #ffffff08;
    top: -10%;
    left: -100%;
    transform: rotate(-45deg);
    height: 60rem;
    transition: .7s all;
}

.team__card:hover {
    border: 1px solid #ffffff44;
    box-shadow: 0 7px 50px 10px #000000aa;
    transform: scale(1.015);
    filter: brightness(1.3);
}

.team__card:hover::before {
    filter: brightness(.5);
    top: -100%;
    left: 200%;
}

.team__card__img {
    width: 100%;
    height: 300px;
    object-fit: cover;
}

.team__card__name {
    font-size: 3rem;
    font-weight: bold;
    text-align: center;
}

.team__card__footer {
    font-size: 2rem;

    color: var(--primary-color);
    text-align: center;
}

@media screen and (max-width: 750px) {
    .team__card {
        margin: 2rem;
        width: 300px;
    }

    .team__card__name {
        font-size: 5rem;
    }


}