.result {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}

.result__main__title {
    font-size: 10rem;
    text-align: center;
    padding-bottom: 5rem;
}