.statement {
    width: 100%;
    height: 100dvh;
    position: relative;
}

.statement__back__container {
    position: fixed;
    left: 5rem;
    top: 5rem;
    width: 5rem;
    height: 5rem;
    z-index: 10;
    cursor: pointer;
}

.statement__back {
    color: white;
    font-size: 4rem;
    cursor: pointer;
    font-weight: 300;
}

.statement__container {
    width: 100%;
    height: 100%;
    padding: 10rem 5rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    backdrop-filter: blur(1px);
    z-index: 5;
}